import { graphql } from "gatsby";
import { propDataTypes, blogDataDefaults } from "../../../prop-types";
import { imageManager } from "../../../components/Image";
import SupportDoc from "../../../layouts/WithSideBarPage/_supportDocPage";

const XLFunctionFindNumber = ({ data }) => (
  <SupportDoc
    frontmatter={data.markdownRemark.frontmatter}
    htmlAst={data.markdownRemark.htmlAst}
    allPagesComments={data.allPagesComments}
    imageRenderer={imageManager(data.pageImages)}
  />
);

XLFunctionFindNumber.propTypes = propDataTypes;
XLFunctionFindNumber.defaultProps = blogDataDefaults;

export default XLFunctionFindNumber;

export const query = graphql`
  query getXLFunctionFindNumber(
    $path: String!
    $siteLanguage: String!
    $commentsPage: String!
  ) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      htmlAst
      frontmatter {
        seo {
          description
          link
          title
        }
        path
        bannerTitle
        bannerSubTitle
        href
        featureToolkit
        sidebarTitle
      }
    }
    allPagesComments(
      sort: { fields: id__normalized, order: ASC }
      filter: { pageKey: { eq: $commentsPage } }
    ) {
      nodes {
        id__normalized
        author
        date(formatString: "MMMM D, YYYY [at] HH:mm", locale: $siteLanguage)
        content
        parentId
        avatarUrl
      }
    }
    pageImages:allFile(
      filter: {
        relativePath: {
          glob: "images/**"
        },
        extension: {
          in: ["png", "jpg", "jpeg"]
        }
      }
    )
    {
      nodes {
        ...stdFluidImage
      }
    }
  }
`;
